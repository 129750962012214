<template>
  <div class="app-footer">
    <div class="app-footer__cell app-footer__cell--1">
      <router-link :to="{name: 'Impressum'}">
        Impressum
      </router-link>
    </div>
    <div class="app-footer__cell app-footer__cell--2">
      <a :href="`mailto:${email}`">
        Kontakt
      </a>
    </div>
    <div class="app-footer__cell app-footer__cell--3">
      <router-link :to="{name: 'Datenschutz'}">
        Privacy
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: process.env.VUE_APP_EMAIL
    }
  }
}
</script>

<styles lang="scss">
@use "txt";
@use "vars";

.app-footer {
  display: grid;
  grid-template:
    "cell--1 cell--2 cell--3" 2.5em /
    1fr 1fr 1fr;
  background-color: vars.$blue;
  grid-gap: 1em;
  padding: 0 vars.$body-padding-h;
}

.app-footer__cell {
  display: flex;
  align-items: center;
  min-width: 0;

  a {
    text-decoration: none;
    font-family: vars.$font-fam-deco;
    @include txt.oneliner;
  }
}

.app-footer__cell--1 {
  grid-area: cell--1;
}

.app-footer__cell--2 {
  grid-area: cell--2;
  justify-content: center;
}

.app-footer__cell--3 {
  grid-area: cell--3;
  justify-content: flex-end;
}
</styles>
