<template>
  <CookieBox
    :consent-store-source="1"
    :privacy-link="locationDatenschutz"
    :banner-style="bannerStyle"
  />
</template>

<script>
import CookieBox from "vue-cookie-box"
import "vue-cookie-box/dist/vue-cookie-box.css"

import styles from "@/scss/_export.scss"

export default {
  components: {
    CookieBox
  },

  computed: {
    // TODO put the following two values in data.
    bannerStyle() {
      return {
        backgroundColor: styles.blue,
        color: styles.white
      }
    },
    locationDatenschutz() {
      return {
        name: "Datenschutz"
      }
    }
  }
}
</script>
