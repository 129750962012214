<template>
  <div class="app">
    <AppNav class="app__app-nav" />

    <div class="app__router-view">
      <router-view />
    </div>

    <AppFooter class="app__app-footer" />

    <CookieBanner />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue"
import AppNav from "@/components/AppNav.vue"
import CookieBanner from "@/components/CookieBanner.vue"

export default {
  components: {
    AppFooter,
    AppNav,
    CookieBanner
  }
}
</script>

<styles lang="scss">
@use "~normalize.css/normalize";

@use "styles";

/* Make footer pin to page bottom when there's little content */
.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app__router-view {
  flex: 1 0 auto;
}
</styles>
