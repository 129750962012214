export default class LinkExt {
  /**
   * @param {String} href
   * @param {String} txt
   */
  constructor(href, txt) {
    this.href = href
    this.txt = txt
  }
}
