import LinkExt from "@/models/LinkExt.js"
import Topic from "@/models/Topic.js"

export default class ShowCase {
  /**
   * @param {String} name
   * @param {Topic[]} topics
   */
  constructor(name, topics) {
    this.name = name
    this.topics = topics
  }
}

export const showCases = [
  /*
  new ShowCase("Services", [
    new Topic(
      "Programmierung",
      [
        "Gibt es dafür kein Programm? Diese Frage stellt sich oft bei repetitiven Aufgaben, die viel Zeit beanspruchen. Oder wenn eine große Datenmenge ausgwertet werden soll. Falls es für Ihren Anwendungsfall noch kein Programm gibt, kann ich eine individuelle Lösung für Sie erstellen."
      ]
    ),
    new Topic(
      "Webseiten",
      [
        "Viele Webseiten sehen sich zum verwechseln ähnlich, sind langsam und funktionieren nicht auf allen Endgeräten. Warum? Agenturen arbeiten oft mit Vorlagen, die sie nur minimal für Kunden anpassen. Sie wollen eine Webseite die besonders ist und einfach funktioniert? Melden Sie sich."
      ]
    ),
    new Topic(
      "SEO",
      [
        "Die Ranglisten von Google und anderen Suchmaschinen werden automatisch von Algorithmen erstellt. Wenn der Algorithmus die Struktur oder den Inhalt Ihrer Webseite nicht versteht, dann landen Sie auf einem der hinteren Plätze. Ich kann Ihnen helfen, Ihre Webseite weiter oben zu platzieren."
      ]
    ),
    new Topic(
      "Online Marketing",
      [
        "Keine Ideen für Posts bei Social Media? Keine Zeit, um sich in komplizierte Systeme für Online-Werbung einzuarbeiten? Keine Ahnung, wie die Nutzung einer Webseite gemessen und ausgewertet werden kann? Ich helfe Ihnen gerne weiter."
      ]
    )
  ]),
  */
  new ShowCase("Projekte", [
    new Topic(
      "Open Source - Contributor",
      [
        "Rust, WebAssembly & Blockchain."
      ],
      {
        linkExt: new LinkExt("https://github.com/mooori", "Contributions ansehen")
      }
    ),
    new Topic(
      "Onlyhere - E-commerce Plattform",
      [
        "Das digitale Shopping-Quartier für #supportyourlocal. Lokale Anbieter können hier in 3 Schritten einfach und schnell ihren eigenen Webshop erstellen."
      ],
      {
        linkExt: new LinkExt("https://onlyhere.io", "Projekt ansehen")
      }
    ),
    new Topic(
      "LunchGuide - Gastronomie Plattform",
      [
        "Neue Restaurants finden und den Mittagstisch aller Lieblings-Lokale auf einen Blick sehen."
        // "Wirten bietet LunchGuide verschiedene Plugins, um die eigene Webseite auf dem Laufenden zu halten und für fremdsprachige Touristen zugänglicher zu machen."
      ],
      {
        linkExt: new LinkExt("https://lunchguide.de", "Projekt ansehen")
      }
    ),
    new Topic(
      "5 - Webseite",
      [
        "5 ist mehr als ein Gourmetrestaurant, mehr als eine Bar, mehr als eine Lounge, mehr als ein Café. 5 ist Leidenschaft für das besondere."
      ],
      {
        linkExt: new LinkExt("https://www.5.fo", "Webseite ansehen")
      }
    ),
    new Topic(
      "Christiane Zielke - Webseite",
      [
        "Mode-Einzelhandel mit Freude an Beratung und Gespür für individuellen Stil. Modisch anspruchsvoll, aber auf echte Bedürfnisse zugeschnitten."
      ],
      {
        linkExt: new LinkExt("https://christiane-zielke.de/", "Webseite ansehen")
      }
    ),
    new Topic(
      "La nuova Trattoria Da Franco - Webseite",
      [
        "DER Italiener im Herzen von Stuttgart und weit über die Stadtgrenzen hinaus bekannt."
      ],
      {
        linkExt: new LinkExt("https://lanuovatrattoriadafranco.de/", "Webseite ansehen")
      }
    ),
    new Topic(
      "Lo Stivale - Webseite",
      [
        "Drei Jungs aus Italien bringen die Küche ihrer Heimat nach Stuttgart-West."
      ],
      {
        linkExt: new LinkExt("https://www.lo-stivale-stuttgart.de/", "Webseite ansehen")
      }
    )
  ]),
  new ShowCase("Profile", [
    new Topic(
      "Github",
      [
        "Open source contributions."
      ],
      {
        linkExt: new LinkExt("https://github.com/mooori/", "Profil ansehen")
      }
    ),
    new Topic(
      "LinkedIn",
      [
        "Persönliche Infos."
      ],
      {
        linkExt: new LinkExt("https://www.linkedin.com/in/moritz-zielke-39120868/", "Profil ansehen")
      }
    )
  ])
]
