export default class Topic {
  /**
   * @param {String} name
   * @param {String[]} descr - paragraphs
   * @param {Object} [detail]
   * @param {LinkExt} [detail.linkExt]
   * @param {String} [detail.logoSrc]
   */
  constructor(name, descr, detail = {}) {
    this.name = name
    this.descr = descr
    this.detail = detail
  }
}
