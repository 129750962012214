<template>
  <div class="home">
    <div class="home__showcases">
      <ShowCase
        v-for="(s, i) in showCases"
        :key="s.name"
        class="home__show-case"
        :class="{'home__show-case--first': i === 0}"
        :show-case="s"
      />
    </div>
  </div>
</template>

<script>
import {showCases} from "@/models/ShowCase.js"
import ShowCase from "@/components/ShowCase.vue"

export default {
  components: {
    ShowCase
  },

  data() {
    return {
      showCases: showCases
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "nav";
@use "vars";

.home {
  padding: app.$view-margin-v 0;
  height: 100%;

  /* Vertically center content. */
  display: flex;
  align-items: center;
}

.home__showcases {
  max-width: app.$max-content-w;
  margin: 0 auto;
  @include app.bodypadding-h;
}

.home__show-case {
  margin-top: 3em;
}

.home__show-case--first {
  margin-top: 0;
}
</styles>
