<template>
  <div class="show-case">
    <h1 class="show-case__name">
      {{ showCase.name }}
    </h1>
    <component
      :is="toggleIcon"
      class="show-case__toggle"
      @click.native="showTopics = !showTopics"
    />
    <ul
      v-show="showTopics"
    >
      <li
        v-for="(t, i) in showCase.topics"
        :key="t.name"
        class="show-case__li"
        :class="{'show-case__li--first': i === 0}"
      >
        <ShowCaseTopic :topic="t" />
      </li>
    </ul>
  </div>
</template>

<script>
import NucleoOEAdd from "@/components/svg/NucleoOEAdd.vue"
import NucleoOEDelete from "@/components/svg/NucleoOEDelete.vue"
import ShowCase from "@/models/ShowCase.js"
import ShowCaseTopic from "@/components/ShowCaseTopic.vue"

export default {
  components: {
    NucleoOEAdd,
    NucleoOEDelete,
    ShowCaseTopic
  },

  props: {
    showCase: {
      type: ShowCase,
      required: true
    }
  },

  data() {
    return {
      showTopics: false
    }
  },

  computed: {
    toggleIcon() {
      return this.showTopics ? "NucleoOEDelete" : "NucleoOEAdd"
    }
  }
}
</script>

<styles lang="scss">
@use "svg";
@use "vars";

.show-case__name {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5em;
}

.show-case__toggle {
  display: block;
  $d: 1em;
  width: $d;
  height: $d;
  margin: 0.25em auto 0 auto;
  cursor: pointer;

  $col: vars.$white;
  @include svg.nucleo-o-e-add($col);
  @include svg.nucleo-o-e-delete($col);
}

.show-case__li {
  margin-top: 2em;
}

.show-case__li--first {
  margin-top: 1em;
}
</styles>
