import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import(/* webpackChunkName: "Datenschtuz" */ "@/views/Datenschutz.vue")
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import(/* webpackChunkName: "Impressum" */ "@/views/Impressum.vue")
  }
  // Use lazy-load for routes other than "Home".
]

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    // It's a popstate navigation (back/forward button).
    return savedPosition
  } else {
    return {x: 0, y: 0}
  }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router
