<template>
  <div class="show-case-topic">
    <h2 class="show-case-topic__name">
      {{ topic.name }}
    </h2>
    <p
      v-for="p in topic.descr"
      :key="p"
    >
      {{ p }}
    </p>
    <p v-if="topic.detail.linkExt">
      <a
        :href="topic.detail.linkExt.href"
        target="_blank"
      >
        {{ topic.detail.linkExt.txt }}
      </a>
    </p>
  </div>
</template>

<script>
import Topic from "@/models/Topic.js"

export default {
  props: {
    topic: {
      type: Topic,
      required: true
    }
  }
}
</script>

<styles lang="scss">
.show-case-topic {
  p {
    margin-top: 0.5em;
    &:first-child {
      margin-top: 0;
    }
  }
}
</styles>
